import { createInputMessageToApprove } from '../createInputMessageToApprove';

const mockValidateForms = jest.fn();
const mockValidateCharges = jest.fn();
const mockValidateCommitments = jest.fn();
const mockValidateReports = jest.fn();
const mockValidateSurveys = jest.fn();

jest.mock('../../../utils/validateForms', () => ({
  validateForms: (a) => mockValidateForms(a),
}));

jest.mock('../../../utils/validateCharges', () => ({
  validateCharges: (a) => mockValidateCharges(a),
}));

jest.mock('../../../utils/validateCommitments', () => ({
  validateCommitments: (a) => mockValidateCommitments(a),
}));

jest.mock('../../../utils/validateReports', () => ({
  validateReports: (a) => mockValidateReports(a),
}));

jest.mock('../../../utils/validateSurveys', () => ({
  validateSurveys: (a) => mockValidateSurveys(a),
}));

describe('createInputMessageToApprove', () => {
  beforeEach(() => {
    jest.restoreAllMocks();
  });

  const values = {
    entity: {
      messageToApprove: {
        id: 1,
      },
    },
    allRecipients: [
      {
        id: 1,
        selected: true,
      },
    ],
    allGroups: [],
    tags: [],
  };
  const approverId = 1;
  const selectedAll = false;

  it('should return null when passed empty input', () => {
    const selectedAll = false;
    const expectedOutput = null;
    const result = createInputMessageToApprove({}, null, selectedAll);
    expect(result).toEqual(expectedOutput);
  });

  it('should return an object with the necessary data to send an approval message', () => {
    const expectedOutput = {
      messageToApproveId: 1,
      entityApproverId: 1,
      recipients: {
        entityIds: [1],
        groupIds: [],
      },
      medias: [],
      tags: [],
    };
    const result = createInputMessageToApprove(values, approverId, selectedAll);
    expect(result).toEqual(expectedOutput);
  });

  it('should call validate forms', () => {
    const input = { ...values, forms: [{ id: 1 }] };
    createInputMessageToApprove(input, null, false);
    expect(mockValidateForms).toHaveBeenCalledWith([{ id: 1 }]);
  });

  it('should call validate charges', () => {
    const input = { ...values, charges: [{ id: 1 }] };
    createInputMessageToApprove(input, null, false);
    expect(mockValidateCharges).toHaveBeenCalledWith([{ id: 1 }]);
  });

  it('should call validate commitments', () => {
    const input = { ...values, commitments: [{ id: 1 }] };
    createInputMessageToApprove(input, null, false);
    expect(mockValidateCommitments).toHaveBeenCalledWith([{ id: 1 }]);
  });

  it('should call validate reports', () => {
    const input = { ...values, reports: [{ id: 1 }] };
    createInputMessageToApprove(input, null, false);
    expect(mockValidateReports).toHaveBeenCalledWith([{ id: 1 }]);
  });

  it('should call validate surveys', () => {
    const input = { ...values, surveys: [{ id: 1 }] };
    createInputMessageToApprove(input, null, false);
    expect(mockValidateSurveys).toHaveBeenCalledWith([{ id: 1 }]);
  });
});
