import { emitSendCommunicationEvent } from '../emitSendCommunicationEvent';
import * as utils from '../../../../utils';
import mixpanel from '../../../../lib/mixpanel';

jest.useFakeTimers({
  advanceTimers: false,
  legacyFakeTimers: false,
  now: 0,
});

describe('emitSendCommunicationEvent', () => {
  beforeEach(() => {
    jest.clearAllMocks();
    jest.spyOn(mixpanel, 'sendEvent').mockImplementation(jest.fn());
  });

  const params = {
    tags: [{ name: 'Group1' }, { name: 'Group2' }],
    values: {
      commitments: ['commitment1'],
      reports: ['report1'],
      surveys: ['survey1'],
      charges: ['charge1'],
      forms: ['form1'],
      labelId: 'label1',
      sendAt: new Date(),
      pin: true,
      noReply: true,
      public: true,
      recipients: ['recipient1', 'recipient2'],
    },
    labels: [{ id: 'label1', title: 'Label1' }],
  };

  it('should send event with correct properties when all values are provided', () => {
    emitSendCommunicationEvent(params);

    expect(mixpanel.sendEvent).toHaveBeenCalledWith({
      customProperties: {
        advanced_settings: 'yes',
        advanced_settings_options: [
          'Importante',
          'Pública',
          'Deasabilitar respostas',
        ],
        button_name: 'enviar_comunicado',
        labels: 'Label1',
        quantity_recipients_selected: 2,
        scheduling: 'yes',
        tags: ['Group1', 'Group2'],
        type: ['Compromisso', 'Relatório', 'Enquete', 'Cobrança', 'Formulário'],
        type_scheduling: 'Customizado',
      },
      identifierName: 'enviar_comunicado',
      name: 'BUTTON_CLICKED',
      pageName: 'CAIXA_DE_ENTRADA',
      scope: 'COMUNICADOS',
    });
  });

  it('should be able to send an event of a communication with the send date set to the next week', () => {
    const now = new Date();
    params.values = {
      ...params.values,
      sendAt: utils.roundDate(new Date(now.getTime() + 7 * 86400000)),
    };

    emitSendCommunicationEvent(params);

    expect(mixpanel.sendEvent).toHaveBeenCalledWith({
      customProperties: {
        advanced_settings: 'yes',
        advanced_settings_options: [
          'Importante',
          'Pública',
          'Deasabilitar respostas',
        ],
        button_name: 'enviar_comunicado',
        labels: 'Label1',
        quantity_recipients_selected: 2,
        scheduling: 'yes',
        tags: ['Group1', 'Group2'],
        type: ['Compromisso', 'Relatório', 'Enquete', 'Cobrança', 'Formulário'],
        type_scheduling: 'Próxima semana',
      },
      identifierName: 'enviar_comunicado',
      name: 'BUTTON_CLICKED',
      pageName: 'CAIXA_DE_ENTRADA',
      scope: 'COMUNICADOS',
    });
  });

  it('should be able to return an error', () => {
    params.values = undefined;
    const result = emitSendCommunicationEvent(params);

    expect(result).toEqual(
      new TypeError(
        // eslint-disable-next-line prettier/prettier
        'Cannot read properties of undefined (reading \'commitments\')',
      ),
    );
  });
});
